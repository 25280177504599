import { View, Text } from "react-native";
import { styles } from "../../styles";

export default function ScoresScreen() {
  return (
    <View style={styles.container}>
      <Text>WIP</Text>
    </View>
  );
}
