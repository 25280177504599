import { useState, useMemo, useRef } from "react";
import { View } from "react-native";
import { Button, Text, Card, IconButton } from "react-native-paper";
import { styles } from "../../styles";

interface Props {
  navigation: any;
  route: {
    params?: {
      quiz?: Quiz;
    };
  };
}

export default function QuizScreen(props: Props) {
  const { navigation, route } = props;
  const { questions } = route.params!.quiz!;

  const nextQuestionTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const [score, setScore] = useState(0);
  const [flaggedQuestions, setFlaggedQuestions] = useState<number[]>([]);
  const [isFinished, setIsFinished] = useState(false);
  const [givenAnswers, setGivenAnswers] = useState<(string | null)[]>(
    new Array(questions.length).fill(null)
  );

  const shuffledQuestions = useMemo(() => {
    return questions
      .map((question) => {
        return {
          ...question,
          answers: [...question.answers].sort(() => Math.random() - 0.5),
        };
      })
      .sort(() => Math.random() - 0.5);
  }, [questions, isFinished]);

  const handleAnswer = (answer: string) => {
    if (givenAnswers[currentQuestionIndex] !== null) {
      return;
    }

    if (answer === question.correctAnswer) {
      setScore(score + 1);
    }

    if (flaggedQuestions.includes(currentQuestionIndex)) {
      setFlaggedQuestions((prev) =>
        prev.filter((i) => i !== currentQuestionIndex)
      );
    }

    setGivenAnswers((prev) => {
      prev[currentQuestionIndex] = answer;
      return prev;
    });

    setSelectedAnswers([...selectedAnswers, answer]);
    nextQuestionTimer.current = setTimeout(() => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    }, 1000);
  };

  const setQuestion = (index: number) => {
    if (nextQuestionTimer.current) {
      clearTimeout(nextQuestionTimer.current);
    }
    setCurrentQuestionIndex(index);
  };

  const toggleFlag = (index: number) => {
    if (nextQuestionTimer.current) {
      clearTimeout(nextQuestionTimer.current);
    }
    if (flaggedQuestions.includes(index)) {
      setFlaggedQuestions((prev) => prev.filter((i) => i !== index));
    } else {
      setFlaggedQuestions((prev) => [...prev, index]);
    }
  };

  const handleRestart = () => {
    setGivenAnswers(new Array(questions.length).fill(null));
    setCurrentQuestionIndex(0);
    setSelectedAnswers([]);
    setScore(0);
    setIsFinished(false);
  };

  const question = shuffledQuestions[currentQuestionIndex];

  return (
    <View style={styles.container}>
      {isFinished ? (
        <>
          <Text style={styles.scoreText}>
            Your score: {((score / questions.length) * 100).toFixed(0)} %
          </Text>
          <Button
            style={styles.restartButton}
            mode="contained"
            onPress={handleRestart}
          >
            Restart Quiz
          </Button>
        </>
      ) : (
        <>
          <View style={styles.flagButtonContainer}>
            <IconButton
              style={styles.flagButton}
              icon={
                flaggedQuestions.includes(currentQuestionIndex)
                  ? "flag"
                  : "flag-outline"
              }
              onPress={() => toggleFlag(currentQuestionIndex)}
            />
          </View>
          <Card style={styles.card}>
            <Card.Title title={question.text} />
            {question.answers.map((answer, index) => (
              <Card.Actions style={styles.cardActions} key={index}>
                <View style={styles.buttonContainer}>
                  <Button
                    mode="contained"
                    onPress={() =>
                      givenAnswers[currentQuestionIndex] === null &&
                      handleAnswer(answer)
                    }
                    disabled={givenAnswers[currentQuestionIndex] !== null}
                    style={[
                      givenAnswers[currentQuestionIndex] === answer
                        ? answer === question.correctAnswer
                          ? styles.correct
                          : styles.incorrect
                        : null,
                      givenAnswers[currentQuestionIndex] !== null &&
                      answer === question.correctAnswer
                        ? styles.correct
                        : null,
                    ]}
                  >
                    {answer}
                  </Button>
                </View>
              </Card.Actions>
            ))}
            <Card.Actions style={styles.storyboard}>
              {shuffledQuestions.map((_, index) => (
                <View style={styles.buttonContainer}>
                  <Button
                    key={index}
                    onPress={() => setQuestion(index)}
                    style={[
                      styles.storyboardButton,
                      index === currentQuestionIndex
                        ? styles.activeStoryboardButton
                        : null,
                      givenAnswers[index] !== null
                        ? givenAnswers[index] ===
                          shuffledQuestions[index].correctAnswer
                          ? styles.correct
                          : styles.incorrect
                        : null,
                      flaggedQuestions.includes(index) ? styles.flagged : null,
                    ]}
                  >
                    {index + 1}
                  </Button>
                </View>
              ))}
            </Card.Actions>
          </Card>

          <Button
            mode="contained"
            disabled={
              !givenAnswers.every((answer) => answer !== null) ||
              flaggedQuestions.length > 0
            }
            onPress={() => setIsFinished(true)}
          >
            Finish Quiz
          </Button>
          {flaggedQuestions.length > 0 && (
            <Text>
              Remember to unflag all questions before finishing the quiz
            </Text>
          )}

          <Button onPress={() => navigation.goBack()}>Go Back</Button>
        </>
      )}
    </View>
  );
}
