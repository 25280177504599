import { ApolloClient, InMemoryCache, HttpLink, gql } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import AsyncStorage from "@react-native-async-storage/async-storage";

const KEYSTONE_API_URL = "https://www.api.happybiology.it/api/graphql";
const httpLink = new HttpLink({ uri: KEYSTONE_API_URL });
const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    "Apollo-Require-Preflight": "true",
  },
}));
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const GET_QUIZ_LIST = gql`
  query {
    quizzes {
      id
      name
    }
  }
`;

const GET_QUIZZES = gql`
  query {
    quizzes {
      id
      name
      questions {
        id
        text
        answers
        correctAnswer
      }
    }
  }
`;

const GET_QUIZ = gql`
  query ($id: ID!) {
    quiz(where: { id: $id }) {
      id
      name
      questions {
        id
        text
        answers
        correctAnswer
      }
    }
  }
`;

const AUTHENTICATE_USER_MUTATION = gql`
  mutation AuthenticateUserMutation($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        item {
          id
          email
        }
      }
      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;

const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation($email: String!, $password: String!) {
    createUser(data: { email: $email, password: $password }) {
      id
      email
    }
  }
`;

export async function register(email: string, password: string) {
  try {
    const result = await client.mutate({
      mutation: CREATE_USER_MUTATION,
      variables: {
        email,
        password,
      },
    });

    if (result.data.createUser) {
      return true;
    } else {
      throw new Error(result.data.createUser.message);
    }
  } catch (error) {
    throw error;
  }
}

export async function login(email: string, password: string) {
  try {
    const result = await client.mutate({
      mutation: AUTHENTICATE_USER_MUTATION,
      variables: {
        email,
        password,
      },
    });

    if (
      result.data.authenticateUserWithPassword.__typename ===
      "UserAuthenticationWithPasswordSuccess"
    ) {
      await AsyncStorage.setItem("isLoggedIn", "true");
      return true;
    } else {
      throw new Error(result.data.authenticateUserWithPassword.message);
    }
  } catch (error) {
    throw error;
  }
}

export async function logout() {
  try {
    await AsyncStorage.clear();
    client.clearStore();
  } catch (error) {
    throw error;
  }
}

function runQuery(query: any, variables?: any) {
  console.log("runQuery", query, variables);
  return new Promise((resolve, reject) => {
    client
      .query({ query, variables })
      .then((result) => {
        if (
          result.errors &&
          result.errors.some(
            (e) => e.message === "You do not have access to this resource"
          )
        ) {
          AsyncStorage.setItem("isLoggedIn", "false");
        }
        resolve(result);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

export function getQuizzes() {
  return runQuery(GET_QUIZZES).then((result: any) => result.data.quizzes);
}
