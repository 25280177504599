import { View } from "react-native";
import { Button } from "react-native-paper";
import { getQuizzes } from "../../lib/api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { CommonActions } from "@react-navigation/native";
import { styles } from "../../styles";
import { logout } from "../../lib/api";

interface Props {
  navigation: any;
  route: {
    params?: {
      handleLogout?: () => void;
    };
  };
}

export default function SettingsScreen(props: Props) {
  const { navigation } = props;
  const { handleLogout } = props.route.params!;

  const refreshQuizzes = () => {
    getQuizzes().then((res: any) => {
      AsyncStorage.setItem("quizzes", JSON.stringify(res));
    });
  };

  return (
    <View style={styles.container}>
      <Button
        mode="contained"
        onPress={refreshQuizzes}
        style={{ marginBottom: 10 }}
      >
        Refresh quizzes
      </Button>
      <Button mode="contained" onPress={handleLogout}>
        Logout
      </Button>
    </View>
  );
}
