import React, { useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { logout } from "./lib/api";
import LoginScreen from "./components/screens/LoginScreen";
import RegisterScreen from "./components/screens/RegisterScreen";
import QuizzesScreen from "./components/screens/QuizzesScreen";
import QuizScreen from "./components/screens/QuizScreen";
import SettingsScreen from "./components/screens/SettingsScreen";
import ScoresScreen from "./components/screens/ScoresScreen";

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function QuizzesStack() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Quizzes" component={QuizzesScreen} />
      <Stack.Screen name="Quiz" component={QuizScreen} />
    </Stack.Navigator>
  );
}

function MainTabs(props: any) {
  return (
    <Tab.Navigator>
      <Tab.Screen name="Quizzes" component={QuizzesStack} />
      <Tab.Screen name="Scores" component={ScoresScreen} />
      <Tab.Screen
        name="Settings"
        component={SettingsScreen}
        initialParams={{ handleLogout: props.route.params.handleLogout }}
      />
    </Tab.Navigator>
  );
}

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkLogin = () => {
    AsyncStorage.getItem("isLoggedIn").then((value) => {
      if (value === "true") {
        setIsLoggedIn(true);
      }
    });
  };

  const handleLogout = async () => {
    logout().then(() => {
      setIsLoggedIn(false);
    });
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <NavigationContainer>
      <Stack.Navigator>
        {isLoggedIn ? (
          <Stack.Screen
            name="Main"
            component={MainTabs}
            initialParams={{
              handleLogout: handleLogout,
            }}
          />
        ) : (
          <>
            <Stack.Screen
              name="Login"
              component={LoginScreen}
              initialParams={{ checkLogin: checkLogin }}
            />
            <Stack.Screen name="Register" component={RegisterScreen} />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
