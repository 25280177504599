import React, { useCallback, useState } from "react";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { styles } from "../../styles";
import { FlatList, View } from "react-native";
import { List, IconButton } from "react-native-paper";

type Props = {
  navigation: any;
};

export default function QuizzesScreen(props: Props) {
  const { navigation } = props;
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);

  const loadQuizzes = useCallback(() => {
    AsyncStorage.getItem("quizzes").then((storedQuizzes) => {
      if (storedQuizzes !== null) {
        setQuizzes(JSON.parse(storedQuizzes));
      }
    });
  }, []);

  useFocusEffect(loadQuizzes);

  const handleQuizSelect = async (quizId: string) => {
    const storedQuizzes = await AsyncStorage.getItem("quizzes");
    if (storedQuizzes !== null) {
      const quizzes = JSON.parse(storedQuizzes);
      const chosenQuiz = quizzes.find((quiz: Quiz) => quiz.id === quizId);
      if (chosenQuiz) {
        navigation.navigate("Quiz", { quiz: chosenQuiz });
      }
    }
  };

  const renderItem = (quiz: any) => (
    <List.Item
      title={quiz.item.name}
      right={() => (
        <IconButton
          icon="play"
          size={20}
          onPress={() => handleQuizSelect(quiz.item.id)}
        />
      )}
    />
  );

  return (
    <View style={styles.container}>
      <FlatList
        data={quizzes}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
    </View>
  );
}
