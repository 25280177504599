import React, { useState } from "react";
import { View } from "react-native";
import { TextInput, Button, Text } from "react-native-paper";
import { register } from "../../lib/api";
import { styles } from "../../styles";
interface Props {
  navigation: any;
}

export default function RegisterScreen(props: Props) {
  const { navigation } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleRegister = () => {
    setError("");
    setLoading(true);
    register(email, password)
      .then(() => {
        setLoading(false);
        navigation.navigate("Login");
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      <View style={styles.formContainer}>
        <TextInput
          label="Email"
          value={email}
          onChangeText={setEmail}
          mode="outlined"
          style={styles.input}
        />
        <TextInput
          label="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          mode="outlined"
          style={styles.input}
        />
        {error ? <Text style={styles.error}>{error}</Text> : null}
        <Button mode="contained" onPress={handleRegister} disabled={loading}>
          {loading ? "Loading..." : "Register"}
        </Button>
        <Button mode="text" onPress={() => navigation.navigate("Login")}>
          Already onboard? Login
        </Button>
      </View>
    </View>
  );
}
