import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",

    justifyContent: "center",
    padding: 16,
  },
  formContainer: {
    maxWidth: 400,
    alignSelf: "center",
  },
  input: {
    marginBottom: 16,
  },
  error: {
    color: "red",
    textAlign: "center",
    marginBottom: 16,
  },
  buttonText: {
    fontSize: 16,
  },
  correct: {
    backgroundColor: "green",
  },
  incorrect: {
    backgroundColor: "red",
  },
  flagged: {
    backgroundColor: "yellow",
  },
  storyboard: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 20,
  },
  storyboardButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "#ddd",
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 5,
  },
  activeStoryboardButton: {
    transform: "scale(1.3)",
  },
  storyboardButtonText: {
    fontSize: 16,
  },
  scoreText: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,
  },
  restartButton: {
    alignSelf: "center",
    marginBottom: 20,
  },
  flagButton: {
    alignSelf: "flex-end",
  },
  flagButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    marginBottom: 20,
  },
  cardActions: {
    justifyContent: "center",
  },
  buttonContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
